import React from 'react';
import Title from "../Common/Title";
import {getImageAltText} from "../../utils/common";
// import Comment from "./Comment";

const FeedbackList = () => (
  <div className="container">
    <div className="mt-5 mb-3">
      <Title position="left">Відгуки</Title>
    </div>
    <div className="comments">
      {/*<Comment/>*/}
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Valya Lyt'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">В клініці чисто. Персонал привітний та уважний. Лікарі - кваліфіковані.
            Окрема подяка лікарю Горошко Сергію Васильовичу</p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Valya Lyt</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Nina S.'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">
            Нереально крутой персонал, и профессионалы своего дела! Девушка на рецепшене - очень внимательная. Сотрудники академии - выше всяких похвал. Сегодня удаляли ребёнку бородавку: 20 минут 150 грн, но сервис просто нереальный! На протяжении всей не очень приятной процедуре - отвлекали ребёнка вопросами, переживали про чувствительность. После процедуры - детальные разъяснения об уходе. И финальный сюрприз - леденец ребёнку за "мужество". Очень рада, что в Черкассах есть такие специалисты.
          </p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Nina S.</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Dmytro Nazarenkov'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">Гарна клініка, все дуже добре організовано. Окрема подяка лікареві Савченко</p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Dmytro Nazarenkov</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Анжеліка Федоренко'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">Сьогодні видаляла родимку на обличчі,про цедура майже безболісна. В лікарні все чисто та стерильно. Лікар(Тарасова Л. М) дуже привітна та знає підхід до пацієнта,підтримала та все доступно пояснила. Так само дівчата на ресепшні привітні,відповіли на всі запитання. Сьогодні мені стало зле,впав тиск,так вони не тільки чаєм напоїли,а ще й пригостили шоколадкою. Дякую ще раз за турботу. Результати операції дізнаюсь пізніше,але надіюсь,що все буде добре. Рекомендую!</p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Анжеліка Федоренко</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Вадим Дмытренко'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">ДЯКУЮ МИРОНЕНКО Альоні Сергіївні за консультацію та допомогу. Доброзичлива, не байдужа до людей, справжній професіонал. Не всі лікарі такі людяні! Альоні Сергіївні можна зателефонувати в  будь-який час і вона НАДАСТЬ консультацію, коли інші лікарі можуть "послати" або, навіть, вимкнути телефон. І я, і моя дружина в захваті від Альони Сергіївни. РЕКОМЕНДУЄМО! Дякуємо Академії, що маєте таких професіоналів, а не шарлатанів! Щодо дівчат з рецепції, дуже вічливі та привітні. Не знаю до кого  там ходила Nika Ti, але Альона Сергіївна дуже ХОРОШИЙ лікар-хірург, який і вислухає, і сто разів перепитає за самопочуття, і все педантично зробить! Ставлю 5 зірок за хорошу ціну Академії та професіоналізм лікаря!!!</p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Вадим Дмытренко</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Алена Коротецкая'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">Сегодня делала операцию по удалению варикозных вен в Академии Лазерной Медицины у хирурга Горошко Сергея Васильевича.
            Я целенаправленно ехала именно к нему из Киева, так как его советовали несколько моих знакомых, которых он оперировал и они его очень хвалили)
            Несмотря на то, что я очень боялась, всё прошло хорошо)
            Еще добавлю, что в клинике чисто, отличный мед. персонал, очень дружелюбные, вежливые, и просто прекрасные люди.
          </p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Алена Коротецкая</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
      <div className="comment">
        <div className="comment-author-ava">
          <img src={`https://eu.ui-avatars.com/api/?name=${'Екатерина Огиенко'}&background=random`} alt={getImageAltText()} width="60" height="50" />
        </div>
        <div className="comment-body panel">
          <p className="comment-text">Сьогодні були на прийомі у Андрія Миеолайовича з дитиною 6-ти місяців.Все дуже сподобалось!І доброзичлива зустріч на ресепшені і чудовий лікар.Андрій Миколайович провів консультацію,своєю посмішкою зачарув нашу дитину і вона не плакала і найбільше,що мене вразило з нас не взяли гроші,а побажали здоров'я!Успіхів ВАМ і процвітання!!!</p>
          <div className="comment-footer text-end">
            <span className="comment-meta">Екатерина Огиенко</span>
            {/*<span className="comment-meta">26.07.2021</span>*/}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeedbackList;