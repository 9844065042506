import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FeedbackForm from "../components/Feedback/FeedbackForm";
import FeedbackList from "../components/Feedback/FeedbackList";

const Feedback = () => (
  <Layout>
    <Seo title="Відгуки"/>
    <FeedbackForm />
    <FeedbackList />
  </Layout>
);

export default Feedback;