import React from 'react';
import ContactsForm from "../ContactsInfo/ContactsForm";
import Title from "../Common/Title";

const FeedbackForm = () => (
  <div className="panel my-5">
    <div className="container">
      <div className="mb-3">
        <Title position="left">Залишити відгук</Title>
      </div>
      <ContactsForm/>
    </div>
  </div>
);

export default FeedbackForm;